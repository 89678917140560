import { BrowserRouter } from 'react-router-dom';
import SideBar from './components/Sidebar/Sidebar';
import Content from './components/Content/Content';
import './App.css';

function App() {
  return (
    <div className="flex row app">
      <BrowserRouter>
        <SideBar />
        <Content />
      </BrowserRouter>
    </div>
  );
}

export default App;
