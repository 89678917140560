import './SidebarLinkContainer.css';
import { Link } from 'react-router-dom';

function SidebarLinkContainer() {
  return (
    <div className="sidebar_content flex column">
      <Link to="/about-me" className="link_isolated">
        ABOUT
      </Link>
      <Link to="/">PICTURES</Link>
      <Link to="exhibition">EXHIBITION</Link>
    </div>
  );
}

export default SidebarLinkContainer;
