import './Sidebar.css';
import SidebarLinkContainer from './SidebarLinkContainer';

function SideBar() {
  return (
    <div className="sidebar">
      <div className="sidebar_content_sticky">
        <img
          src={`${process.env.PUBLIC_URL}/img/placeholder.png`}
          alt="placeholder"
          className="sidebar_img"
        />
        <SidebarLinkContainer />
      </div>
    </div>
  );
}
export default SideBar;
