import { useParams } from 'react-router-dom';
import images from '../../imgNames.json';

import './ImagePreview.css';

function ImagePreview() {
  const { filename } = useParams();
  const { arrayComposition } = images;
  const { description } = arrayComposition.find(
    (currentImage) => currentImage.filename === filename
  );
  return (
    <div className="container_center_horizontal w-100">
      <div className="flex column align-center vh-100">
        <div className="container_center_horizontal img_preview_container">
          <img
            className="h-100"
            src={`${process.env.PUBLIC_URL}/img/dynamic_img/composition/${filename}`}
            alt=""
          />
        </div>
        <div className="img_description w-100">{description || ''}</div>
      </div>
    </div>
  );
}

export default ImagePreview;
