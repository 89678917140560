// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import './AboutMe.css';

function AboutMe(props) {
  const { lang, children } = props;
  return (
    <div className="container_about">
      <p className="text_lang">{lang}</p>
      <div className="text_paragraph_about">{children}</div>
    </div>
  );
}

AboutMe.propTypes = {
  lang: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AboutMe.defaultProps = {
  lang: '',
};

export default AboutMe;
