import { ReactComponent as GmailLogo } from './gmail_logo.svg';
import { ReactComponent as InstagramLogo } from './instagram_logo.svg';
import './SocialLinks.css';
import AboutMe from '../AboutMe/AboutMe';

function SocialLinks() {
  return (
    <AboutMe>
      <div className="container_social_links">
        <span className="container_social_link">
          <InstagramLogo className="svg_social-link" />
          <a href="https://www.instagram.com/zetopow">Zetopow</a>
        </span>
        <span className="container_social_link">
          <GmailLogo className="svg_social-link" />
          <a href="mailto:zetopow@gmail.com">zetopow@gmail.com</a>
        </span>
      </div>
    </AboutMe>
  );
}

export default SocialLinks;
