import { Route, Routes } from 'react-router-dom';
import AboutPage from '../AboutPage';

import ImagePreview from '../ImagePreview/ImagePreview';
import {
  IndexImgIterator,
  ExhibitionImgIterator,
} from '../ImgIterator/ImgIterator';

function Content() {
  return (
    <Routes>
      <Route index element={<IndexImgIterator />} />
      <Route path="exhibition" element={<ExhibitionImgIterator />} />
      <Route path="about-me" element={<AboutPage />} />
      <Route path="/image/:filename" element={<ImagePreview />} />
    </Routes>
  );
}

export default Content;
