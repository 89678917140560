// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './ImgIterator.css';
import imagesNames from '../../imgNames.json';
import CopyrightSVG from './CopyrightSVG/CopyrightSVG';

function ImgIterator({ imgGenerator, lastBlock, images }) {
  const img = images.map((item) => imgGenerator(item));

  return (
    <div className="container_img">
      {img}
      {lastBlock}
    </div>
  );
}

ImgIterator.propTypes = {
  imgGenerator: PropTypes.func.isRequired,
  lastBlock: PropTypes.node.isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
};

function IndexImgGenerator(item) {
  const { filename } = item;
  return (
    <Link to={`/image/${filename}`} key={filename}>
      <img
        className="img"
        src={`${process.env.PUBLIC_URL}/img/dynamic_img/composition/${filename}`}
        alt=""
      />
    </Link>
  );
}
const IndexLastBlock = (
  <div className="img container_center">
    <CopyrightSVG />
    2022 ZETO
  </div>
);

function IndexImgIterator() {
  return (
    <ImgIterator
      imgGenerator={IndexImgGenerator}
      lastBlock={IndexLastBlock}
      images={imagesNames.arrayComposition}
    />
  );
}

function ExhibitionImgGenerator(name) {
  return (
    <img
      key={name}
      className="exhibition_img"
      src={`${process.env.PUBLIC_URL}/img/dynamic_img/exhibition/${name}`}
      alt=""
    />
  );
}
const ExhibitionLastBlock = (
  <div className="exhibition_img container_center">
    Exposition Paris 2021 <br />
    Exhibition Paris 2021
  </div>
);

function ExhibitionImgIterator() {
  return (
    <ImgIterator
      imgGenerator={ExhibitionImgGenerator}
      lastBlock={ExhibitionLastBlock}
      images={imagesNames.arrayExhibition}
    />
  );
}

export { IndexImgIterator, ExhibitionImgIterator };
