import './CopyrightSVG.css';

function CopyrightSVG() {
  return (
    <svg
      className="icon_copyright"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7_127)">
        <path
          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
          fill="#4B4B4B"
        />
        <path
          d="M8.00001 14.3673C11.5166 14.3673 14.3674 11.5166 14.3674 8C14.3674 4.48341 11.5166 1.63265 8.00001 1.63265C4.48342 1.63265 1.63266 4.48341 1.63266 8C1.63266 11.5166 4.48342 14.3673 8.00001 14.3673Z"
          fill="white"
        />
        <path
          d="M7.99999 12.4898C10.4796 12.4898 12.4898 10.4796 12.4898 8C12.4898 5.52035 10.4796 3.5102 7.99999 3.5102C5.52034 3.5102 3.51019 5.52035 3.51019 8C3.51019 10.4796 5.52034 12.4898 7.99999 12.4898Z"
          fill="#4B4B4B"
        />
        <path
          d="M8.00001 10.449C9.35254 10.449 10.449 9.35253 10.449 8C10.449 6.64747 9.35254 5.55102 8.00001 5.55102C6.64747 5.55102 5.55103 6.64747 5.55103 8C5.55103 9.35253 6.64747 10.449 8.00001 10.449Z"
          fill="white"
        />
        <path
          d="M10.0408 6.93878H12.5714V8.97959H10.0408V6.93878Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_127">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CopyrightSVG;
