import AboutMe from './AboutMe/AboutMe';
import Text from './aboutText.json';
import './AboutPage.css';
import SocialLinks from './SocialLinks/SocialLinks';

function AboutPage() {
  const frParagraphs = Text.fr.map((paragraph) => (
    <p key={paragraph.slice(0, 5)}>{paragraph}</p>
  ));
  const enParagraphs = Text.en.map((paragraph) => (
    <p key={paragraph.slice(0, 5)}>{paragraph}</p>
  ));

  return (
    <div className="container_center w-100">
      <div className="container_abouts">
        <AboutMe lang="FR">{frParagraphs}</AboutMe>
        <AboutMe lang="EN">{enParagraphs}</AboutMe>
        <SocialLinks />
      </div>
      <div className="w-100">
        <img
          className="img_flower"
          src={`${process.env.PUBLIC_URL}/img/flower.png`}
          alt="Flower"
        />
      </div>
    </div>
  );
}

export default AboutPage;
